async function fetchAndLoadImage(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          reject(new Error('Network response was not OK'));
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        const image = new Image();
        image.src = imageUrl;

        image.onload = function () {
          resolve(image);
        };

        image.onerror = function () {
          reject(new Error('Failed to load image'));
        };
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });
}


async function loadMultipleImages(urls) {
  const promiseArray = urls.map((url) => fetchAndLoadImage(url));
  const images = await Promise.all(promiseArray);
  return images;
}

export { fetchAndLoadImage, loadMultipleImages }
