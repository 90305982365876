import isMobile from 'ismobilejs';
import { animate } from "motion"
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import * as Img from  "./images.js";
import "./main.css";

const elm = require("./elm.js");

const WIDTH = 1024;
const HEIGHT = 1024;

let textures;
let app;

class CopyElement extends HTMLElement{
    constructor() { 
	super(); 
	this.addEventListener("click", this.copy.bind(this));
    }
    copy() {
	const blah = this.querySelector("input");
	blah.select();
	blah.setSelectionRange(0, 99999);
	document.execCommand('copy');
    }
    connectedCallback() {
	const blah = this.querySelector("input");
	blah.select();
	blah.setSelectionRange(0, 99999);
    }
    attributeChangedCallback() {}
    static get observedAttributes() {}
}

import pupgeometry from "../pupp3.gltf";

class GPUPickHelper {
  constructor() {
    // create a 1x1 pixel render target
    this.pickingTexture = new THREE.WebGLRenderTarget(1, 1);
    this.pixelBuffer = new Uint8Array(4);
  }
  pick(cssPosition, scene, camera, renderer) {
    const {pickingTexture, pixelBuffer} = this;
 
    // set the view offset to represent just a single pixel under the mouse
    const pixelRatio = renderer.getPixelRatio();

    camera.setViewOffset(
	renderer.getContext().drawingBufferWidth,   // full width
	renderer.getContext().drawingBufferHeight,  // full top
	cssPosition.x * pixelRatio | 0,             // rect x
	cssPosition.y * pixelRatio | 0,             // rect y
	1,                                          // rect width
	1,                                          // rect height
    );
    // render the scene
    renderer.setRenderTarget(pickingTexture);
    renderer.render(scene, camera);
    renderer.setRenderTarget(null);
    // clear the view offset so rendering returns to normal
    camera.clearViewOffset();
    //read the pixel
    renderer.readRenderTargetPixels(
	pickingTexture,
	0,   // x
	0,   // y
	1,   // width
	1,   // height
	pixelBuffer);
 
    const id = pixelBuffer[1];
//	(pixelBuffer[0] << 16) |
//	(pixelBuffer[1] <<  8) |
//	(pixelBuffer[2] <<  0);
 
    return id;
  }
}


class PuppyView extends HTMLElement {
    static elements = {};

    camera; 
    controls; 
    scene; 
    renderer;

    palettes;
    paletteTexture;
    maxColors = 16;
    paletteTextureWidth = 16;
    baseTexture;
    baseTextureCtx;

    constructor() { 
	super(); 

	this.scene = new THREE.Scene();

	this.renderer = new THREE.WebGLRenderer( { 
	    antialias: true, 
	    alpha: true,
	    premultipliedAlpha: false,

	} );
	this.renderer.setPixelRatio( window.devicePixelRatio );
	this.renderer.setSize( WIDTH, HEIGHT);
	this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
	this.renderer.toneMappingExposure = 1;

	this.camera = new THREE.PerspectiveCamera( 40, WIDTH/HEIGHT , 1, 1000 );
	this.camera.position.set( -4, 1, 4 );

	// controls

	this.controls = new OrbitControls( this.camera, this.renderer.domElement );
	this.controls.listenToKeyEvents( this.renderer.domElement ); 

	this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
	this.controls.dampingFactor = 0.025;

	this.controls.screenSpacePanning = false;

	this.controls.minDistance = 3.5
	this.controls.maxDistance = 10;
	this.controls.enablePan = false;

	//controls.maxPolarAngle = Math.PI / 2;

	// lights
	const ambientLight = new THREE.AmbientLight( 0xDDD8FF, 1.5);
	this.scene.add( ambientLight );

	const dirLight1 = new THREE.DirectionalLight( 0xFFE7D6, 3 );
	dirLight1.position.set( 0.5, 2, 0.5 );
	this.scene.add( dirLight1 );

	const dirLight2 = new THREE.DirectionalLight( 0xFFE7D6, 2.5 );
	dirLight2.position.set( 0, 3, -5.0 );
	this.scene.add( dirLight2 );

	// palette
	this.palette = new Uint8Array( this.paletteTextureWidth * 4 );
	this.paletteTexture = new THREE.DataTexture( this.palette, this.paletteTextureWidth, 1 );
	this.paletteTexture.minFilter = THREE.NearestFilter;
	this.paletteTexture.magFilter = THREE.NearestFilter;
	this.paletteTexture.colorSpace = THREE.LinearSRGBColorSpace;
	this.palette.set( [ 255, 255, 255, 128 ], 0 );

	this.paletteTexture.needsUpdate = true;
    }

    loop() {
	requestAnimationFrame( this.loop.bind(this) );
	this.controls.update(); 
	this.renderer.render( this.scene, this.camera );
    }
    connectedCallback() {
	PuppyView.elements[this.getAttribute('id')] = this; 

	this.appendChild( this.renderer.domElement );

	this.pickingScene = new THREE.Scene();
	this.pickingScene.background = new THREE.Color( 0 );

	const loader = new GLTFLoader().setPath( window.location.origin + "/" );

	loader.load( pupgeometry, function ( gltf ) {
	    var pup = gltf.scene.getObjectByName( "Scene", true );
	    const tloader = new THREE.TextureLoader();

	    
	    const fragmentShaderReplacements = [
		{ 
		    from: "void main() {",
		    to: `

		vec3 toLinear(vec3 sRGB)
		{
		    bvec3 cutoff = lessThan(sRGB, vec3(0.04045));
		    vec3 higher = pow((sRGB+ vec3(0.055))/vec3(1.055), vec3(2.4));
		    vec3 lower = sRGB/vec3(12.92);

		    return mix(higher, lower, cutoff);
		}

		float toLinear(float sRGB)
		{
		    bool cutoff = sRGB < 0.04045;
		    float higher = pow((sRGB + 0.055)/1.055, 2.4);
		    float lower = sRGB/12.92;

		    return mix(higher, lower, cutoff);
		}


		void main() {
		`
		},
		{
		    from: '#include <common>',
		    to: `
	      #include <common>
	      uniform sampler2D indexTexture;
	      uniform sampler2D paletteTexture;
	      uniform sampler2D camoTexture;
	      uniform sampler2D textureTexture;
	      uniform float paletteTextureWidth;
	    `,
		},
		{
		    from: '#include <color_fragment>',
		    to: `

	      #include <color_fragment>
	      
	      vec4 indexColor = texture2D(indexTexture, vMapUv);
	      float index = indexColor.g * 255.0;
	      vec2 paletteUV = vec2((index + 0.5) / paletteTextureWidth, 0.5);
    
	      vec4 paletteColor = texture2D(paletteTexture, paletteUV);
	      vec4 tintColor;
	      if (paletteColor.a != 0.0) {
	          tintColor = vec4(paletteColor.rgb, 0.0);
	      } 
	      else {
	          tintColor = 
	              texture2D(textureTexture, (vMapUv * vec2(4.66))) * paletteColor.r +
	              texture2D(camoTexture, (vMapUv * vec2(3.66))) * paletteColor.g;
	      }
	      diffuseColor.rgb = mix(diffuseColor.rrr, (diffuseColor.rrr * tintColor.rgb), diffuseColor.g);   
	      //diffuseColor.rgb = vec3(diffuseColor.a);
	      
	    `,
		},
		{
		    from: '#include <lights_phong_fragment>',
		    to: `

	      #define BlendOverlayf(base, blend) 	(base < 0.5 ? (2.0 * base * blend) : (1.0 - 2.0 * (1.0 - base) * (1.0 - blend)))

	      #include <lights_phong_fragment>
	      {
		  material.specularStrength = (specularStrength * BlendOverlayf(indexColor.r, paletteColor.a));
		  material.specularShininess = shininess * indexColor.r;
	      }
	    `,
		},
	    ];
	    const maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy();

	    const camoTexture = new THREE.Texture(textures[4]);
	    camoTexture.wrapS = THREE.RepeatWrapping;
	    camoTexture.wrapT = THREE.RepeatWrapping;
	    camoTexture.colorSpace = THREE.SRGBColorSpace;
	    camoTexture.needsUpdate = true;
	    camoTexture.anisotropy = maxAnisotropy;

	    const textureTexture = new THREE.Texture(textures[5]);
	    textureTexture.wrapS = THREE.RepeatWrapping;
	    textureTexture.wrapT = THREE.RepeatWrapping;
	    textureTexture.colorSpace = THREE.SRGBColorSpace;
	    textureTexture.needsUpdate = true;
	    textureTexture.anisotropy = maxAnisotropy;

	    const canvas1 = document.createElement('canvas'); 
	    canvas1.width = textures[0].width;
	    canvas1.height = textures[0].height;

	    const canvas2 = document.createElement('canvas'); 
	    canvas2.width = textures[1].width;
	    canvas2.height = textures[1].height;

	    //document.body.appendChild(canvas);
	    this.baseTextureCtx = canvas1.getContext('2d');
	    this.baseTextureCtx.drawImage(textures[0], 0, 0);

	    this.indexTextureCtx = canvas2.getContext('2d');
	    this.indexTextureCtx.drawImage(textures[1], 0, 0);

	    this.baseTexture = new THREE.CanvasTexture(canvas1);
	    this.baseTexture.colorSpace = THREE.SRGBColorSpace;
	    this.baseTexture.minFilter = THREE.NearestFilter;
	    this.baseTexture.magFilter = THREE.NearestFilter;
	    this.baseTexture.needsUpdate = true;
	    this.baseTexture.anisotropy = maxAnisotropy;

	    this.indexTexture = new THREE.CanvasTexture(canvas2);
	    this.indexTexture.minFilter = THREE.NearestFilter;
	    this.indexTexture.magFilter = THREE.NearestFilter;
	    this.indexTexture.colorSpace = THREE.LinearSRGBColorSpace;
	    this.indexTexture.needsUpdate = true;
	    this.indexTexture.anisotropy = maxAnisotropy;

	    const material = new THREE.MeshPhongMaterial( {
		map: this.baseTexture,
		shininess: 15.0,
		specular: 0xbDb8a8,
	    });

	    material.onBeforeCompile = function ( shader ) {
		fragmentShaderReplacements.forEach( ( rep ) => {
		    shader.fragmentShader = shader.fragmentShader.replace( rep.from, rep.to );
		});
		shader.uniforms.paletteTexture = { value: this.paletteTexture };
		shader.uniforms.indexTexture = { value: this.indexTexture };
		shader.uniforms.paletteTextureWidth = { value: this.paletteTextureWidth };
		shader.uniforms.camoTexture = { value: camoTexture };
		shader.uniforms.textureTexture = { value: textureTexture };
	    }.bind(this);

	    pup.traverse( function( node ) {
	        if ( node instanceof THREE.Mesh ) {
	            node.material = material;
	        }
	    }.bind(this));

	    this.pickingMaterial = new THREE.MeshBasicMaterial( { map: this.indexTexture } );
	    const picking_pup = pup.clone();


	    picking_pup.traverse( function (node ) {
		if ( node.isMesh ) {
		    node.material = this.pickingMaterial;
		}
	    }.bind(this));

	    //this.scene = this.pickingScene
	    this.scene.add(pup);

	    const pickHelper = new GPUPickHelper();

	    const maxClickTimeMs = 200;
	    const maxMoveDeltaSq = 5 * 5;
	    const startPosition = {};
	    let startTimeMs;
	     
	    const recordStartTimeAndPosition = (event) => {
	      startTimeMs = performance.now();
	      const pos = getCanvasRelativePosition(event);
	      startPosition.x = pos.x;
	      startPosition.y = pos.y;
	    }

	    const getCanvasRelativePosition = (event) => {
	      const rect = this.renderer.domElement.getBoundingClientRect();
	      const ratio = this.renderer.getPixelRatio();
	      return {
		x: (event.clientX - rect.left) * this.renderer.domElement.width  / rect.width / ratio,
		y: (event.clientY - rect.top ) * this.renderer.domElement.height / rect.height / ratio,
	      };
	    }

	    const pick = (event) => {
		const clickTimeMs = performance.now() - startTimeMs;
		if (clickTimeMs > maxClickTimeMs) {
		  return;
		}

		const position = getCanvasRelativePosition(event);

		const moveDeltaSq = (startPosition.x - position.x) ** 2 +
		      	      (startPosition.y - position.y) ** 2;
		if (moveDeltaSq > maxMoveDeltaSq) {
		  return;
		}

		const id = pickHelper.pick(position, this.pickingScene, this.camera, this.renderer);

		app.ports.pupClickedOn.send(id);
	    }

	    this.renderer.domElement.addEventListener('pointerdown', recordStartTimeAndPosition);
	    this.renderer.domElement.addEventListener('pointerup', pick);

	    //this.scene = this.pickingScene;
	    this.pickingScene.add(picking_pup);

	    this.update(this.penis);
	}.bind(this));
	
	//window.addEventListener( 'resize', onWindowResize );

	this.loop();
    }
    attributeChangedCallback() {}
    static get observedAttributes() {}

    update(message) {
	const set = ( area, idx ) => {
	    this.palette.set(
		area.values ? 
		[area.values.r, area.values.g, area.values.b, area.values.a]
		: [area.coordinates.x, area.coordinates.y, 0, 0]
		, idx)
	};


	set(message.crown, 4);
	set(message.base, 8);
	set(message.chin, 12);
	set(message.muzzle_lip, 16);
	set(message.bridge, 20);
	set(message.muzzle, 24);
	set(message.brow, 28);
	set(message.nose, 32);
	set(message.ear_c, 36); // back
	set(message.ear_a, 40); // inner
	set(message.ear_b, 44); // outer
	set(message.jowl, 48); 
	set(message.whiskers, 52); 
	set(message.whiskers, 56); 

	this.scene.children[3].children[5].visible = message.show_jowl;
	this.baseTextureCtx.clearRect(0, 0, 2048, 2048);
	this.baseTextureCtx.drawImage(textures[0], 0, 0);
	this.indexTextureCtx.clearRect(0, 0, 2048, 2048);
	this.indexTextureCtx.drawImage(textures[1], 0, 0);

	const drawSection = (ctx, t, x, y, w, h, sx, sy ) => {
	    ctx.clearRect(x, y, w, h);
	    ctx.drawImage(t, sx, sy, w, h, x, y, w, h);

	    ctx.scale(-1,1);
	    ctx.clearRect((-2048.0 + x), y, w, h);
	    ctx.drawImage(t, sx, sy, w, h, (-2048.0 + x), y, w, h);
	    ctx.scale(-1,1);
	};

	switch (message.muzzle_detail) {
	    case "Seven Holes":
		drawSection(this.baseTextureCtx, textures[2], 652, 1398, 231, 227, 499, 0);
		drawSection(this.indexTextureCtx, textures[3], 652, 1398, 231, 227, 499, 0);

		break;
	    case "Eyelets":
		drawSection(this.baseTextureCtx, textures[2], 720, 1500, 185, 163, 0, 0);
		drawSection(this.indexTextureCtx, textures[3], 720, 1500, 185, 163, 0, 0);
		break;
	    case "Whiskers":
		drawSection(this.baseTextureCtx, textures[2], 628, 1389, 314, 316, 185, 0);
		drawSection(this.indexTextureCtx, textures[3], 628, 1389, 314, 316, 185, 0);
		break;
	    case "Fur":
		drawSection(this.baseTextureCtx, textures[2], 1061, 1369, 523, 431, 0, 316);
		drawSection(this.indexTextureCtx, textures[3], 1061, 1369, 523, 431, 0, 316);
		break;
	}

	if(message.show_ears) {
	    drawSection(this.baseTextureCtx, textures[2], 1453, 461, 69, 91, 566, 316);
	    drawSection(this.indexTextureCtx, textures[3], 1453, 461, 69, 91, 566, 316);
	}

	if(message.show_glass) {
	    drawSection(this.baseTextureCtx, textures[2], 1402, 606, 43, 47, 523, 316);
	    drawSection(this.indexTextureCtx, textures[3], 1402, 606, 43, 47, 523, 316);
	}


	this.baseTexture.needsUpdate = true;
	this.indexTexture.needsUpdate = true;
	this.paletteTexture.needsUpdate = true;
    }
}

customElements.define('puppy-view', PuppyView);
customElements.define('copy-element', CopyElement);

import outputjpg from "../output.jpg" ;
import pickjpg from "../pick_output.png";
import montagejpg from "../montage.jpg";
import pickmontagejpg from "../pick_montage.png";
import camojpg from "../camo.jpg";
import texturedjpg from "../textured.jpg";

const texture_urls = [ 
    outputjpg,
    pickjpg,
    montagejpg,
    pickmontagejpg,
    camojpg,
    texturedjpg,
];

const texturePromise = Img.loadMultipleImages(texture_urls);
texturePromise.then( (t) => {
    const detector = isMobile(window.navigator)
    const isPhone = detector.apple.phone || detector.android.phone
    const element = document.getElementById('elm')

    textures = t;
    const flags = { 
	host: window.location.origin,
	url: window.location.href,
	window_height: window.innerHeight,
	window_width: window.innerWidth,
	isPhone: isPhone,
	camotexture: camojpg,
	texturetexture: texturedjpg,
	};
    app = elm.Elm.Main.init({ node: element, flags: flags });

    app.ports.configChanged.subscribe(function(message) {
	var element = PuppyView.elements[message.id];
	element.update(message)
    });
});

